/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Space } from "antd";
import { NavLink, Outlet } from "react-router-dom";
// import propTypes from "prop-types";
// import classnames from "classnames";

// import css from "./style.scss";
import css from "./style.module.less";


export default function BasicLayout(props) {
  return (
    <main style={{ width: "100%", height: "100%" }}>
      <Outlet />
    </main>
  )
};


BasicLayout.propTypes = {


};
BasicLayout.defaultProps = {


};