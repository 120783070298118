/* eslint-disable react/prop-types */
import React, { useState, useCallback } from "react";
import { Row, Col, Space, Button, Input } from "antd";

import { useInitalValue } from "@/application/hooks/useInitalValue";
import { encode } from "@/utils/aes_crypto/encode";

// import classnames from "classnames";
// import propTypes from "prop-types";

export default function IndexPage() {
  const result = useInitalValue();

  const [keyword, set_keyword] = useState("");

  const handleCallback = useCallback(() => {
    window.location.href = `/search/${encode(keyword)}.html`;
  }, [keyword]);

  return (
    <div>
      <Row justify="center" align="middle" style={{ padding: 20 }}>
        <h1>{result.title}</h1>
      </Row>
      <Row justify="center" align="middle">
        <h2 style={{ fontSize: 16 }}>{result.description}</h2>
      </Row>
      <Row justify="center" align="middle">
        <Col md={12} sm={20}>
          <Space.Compact block size="large">
            <Input
              value={keyword}
              onPressEnter={handleCallback}
              onChange={(event) => set_keyword(event.target.value)}
              placeholder="请输入查询关键字"
            />
            <Button type="primary" onClick={handleCallback}>搜索</Button>
          </Space.Compact>
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ fontSize: 30, padding: 20 }}>
        <h3 style={{ fontSize: 16 }}>热门资源</h3>
      </Row>
      <p style={{ width: "50%", margin: "0px auto", textAlign: "center" }}>
        {result.hot_resources.map((resource_name) => {
          return (
            <a key={resource_name} style={{ margin: "0px 5px" }} href={`${result.request_host}/search/${encode(resource_name)}.html`}>
              <strong>{resource_name}</strong>
            </a>
          )
        })}
      </p>
    </div>
  )
}


IndexPage.propTypes = {

};

IndexPage.defaultProps = {

};