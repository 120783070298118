// @ts-nocheck
/* eslint-disable react/prop-types */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { legacyLogicalPropertiesTransformer, StyleProvider } from "@ant-design/cssinjs";

import { RenderContextProvider } from "@/application/context/render_context";
import { Application } from "@/application/application";

const container = document.getElementById("root");

ReactDOM.hydrate((
  <StyleProvider transformers={[legacyLogicalPropertiesTransformer]} hashPriority="high" ssrInline>
    <RenderContextProvider initial_value={window.initial_value}>
      <BrowserRouter>
        <Application />
      </BrowserRouter>
    </RenderContextProvider>
  </StyleProvider>
), container);