/* eslint-disable react/prop-types */
import React, { useState, useCallback } from "react";
import { Row, Col, Space, Input, Button } from "antd";

import { useInitalValue } from "@/application/hooks/useInitalValue";
import { encode } from "@/utils/aes_crypto/encode";
// import propTypes from "prop-types";
// import classnames from "classnames";

// import css from "./style.scss";
// import css from "./style.less";

export function GlobalSearchInput(props) {

  const result = useInitalValue();
  const [keyword, set_keyword] = useState(props.keyword);

  const handleCallback = useCallback(() => {
    window.location.href = `/search/${encode(keyword)}.html`;
  }, [keyword]);

  return (
    <Row justify="center" align="middle">
      <Col md={12} sm={20}>
        <Space.Compact block size="large">
          <Button>
            <a href={result.request_host}>至简磁力</a>
          </Button>
          <Input
            value={keyword}
            onPressEnter={handleCallback}
            onChange={(event) => set_keyword(event.target.value)}
            placeholder="请输入查询关键字"
          />
          <Button type="primary" onClick={handleCallback}>搜索</Button>
        </Space.Compact>
      </Col>
    </Row>
  )
};


GlobalSearchInput.propTypes = {


};
GlobalSearchInput.defaultProps = {


};