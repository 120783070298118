/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import React, { useCallback } from "react";
import { Space, Empty, Pagination } from "antd";


// import propTypes from "prop-types";
// import classnames from "classnames";
import { GlobalSearchInput } from "@/application/components/GlobalSearchInput";
import { useInitalValue } from "@/application/hooks/useInitalValue";
import { encode } from "@/utils/aes_crypto/encode";

// import css from "./style.scss";
// import css from "./style.less";

export default function SearchPage() {
  const result = useInitalValue();
  const keyword = get(result, "keyword", "");
  const basicKeywordList = get(result, "basicKeywordList", []);
  const list = get(result, "data.list", []);
  const error = get(result, "error", null);
  const page_number = Number(get(result, "data.page_number", 1));

  const handleChange = useCallback((next_page_number) => {
    window.location.href = `/search/${encode(keyword)}/${next_page_number}.html`;
  }, [keyword]);

  return (
    <div style={{ padding: 20 }}>
      <GlobalSearchInput keyword={keyword} />
      {error ? (
        <div style={{ padding: 20, textAlign: "center" }}>
          {error}
        </div>
      ) : (
        <>
          <div style={{ padding: 20, textAlign: "center" }}>
            <h1>{result.title}</h1>
            <h2 style={{ fontSize: 16 }}>{`搜索关键词: "${keyword}"`}</h2>
          </div>
          {list.length ? (
            <Space direction="vertical" style={{ width: "100%" }}>
              {list.map(({ title, href }, index) => (
                <p key={index} style={{ width: "100%" }}>
                  <a style={{ display: "block" }} href={href}>
                    {`${title}-${basicKeywordList.join(",")}`}
                  </a>
                  <span style={{ display: "block", wordWrap: "break-word" }}>
                    {href}
                  </span>
                </p>
              ))}
            </Space>
          ) : (
            <Empty description="抱歉,没有找到相关内容">
              <a href={`https://www.imdb.com/find/?q=${keyword}`}>尝试去imdb.com进行搜索</a>
            </Empty>
          )}
          <div style={{ textAlign: "center" }}>
            <Pagination
              responsive
              pageSize={20}
              size="default"
              current={page_number}
              showSizeChanger={false}
              style={{ display: "inline-block" }}
              total={get(result, "data.total_page", 0) * 20}
              itemRender={(page, type, originalElement) => {
                if (type === "page") {
                  return (<a key={page} href={`${result.request_host}/search/${encode(keyword)}/${page}.html`}>{page}</a>)
                };
                return originalElement;
              }}
              onChange={handleChange}
            />
          </div>
        </>
      )}
    </div>
  )
};


SearchPage.propTypes = {

};

SearchPage.defaultProps = {

};